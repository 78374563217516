import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../../../components/container/Container";
import CustomButton from "../../../components/button/Button";

const MensagemView = () => {
  const location = useLocation();
  const formData = location.state?.previousData || null;
  const navigate = useNavigate();
  const hiddenParagraphRef = useRef(null);
  const [shouldIndent, setShouldIndent] = useState(false);

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = String(data.getDate() + 1).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (hiddenParagraphRef.current) {
      const paragraph = hiddenParagraphRef.current;
      const lineHeight = parseFloat(window.getComputedStyle(paragraph).lineHeight);
      const paragraphHeight = paragraph.offsetHeight;
      const lines = Math.ceil(paragraphHeight / lineHeight);
      setShouldIndent(lines > 1);
    }
  }, [formData.corpo]);

  console.log(formData)

  return (
    <Container titulo={`${formData.titulo}`} style={{ marginTop: "10%" }}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <small>
          <i style={{ color: "black" }}>
            {formatarData(formData.dataCadastro)} - {formData.remetente.profile}
          </i>
        </small>
        <div style={{ position: 'absolute', visibility: 'hidden', height: 'auto', width: '80%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
          <p ref={hiddenParagraphRef} style={{ fontSize: '1em', lineHeight: '1.5em' }}>
            {formData.corpo}
          </p>
        </div>
        <p
          style={{
            color: "black",
            textAlign: 'justify',
            textIndent: shouldIndent ? '4em' : '0em',
            whiteSpace: 'pre-wrap',
            overflowWrap: 'break-word'
          }}
        >
          {formData.corpo}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <CustomButton
          onClick={handleCancel}
          style={{
            width: '150px',
            textAlign: 'center',
          }}
        >
          Fechar
        </CustomButton>
      </div>
    </Container>
  );
};

export default MensagemView;
